<template>
  <v-navigation-drawer
    id="core-navigation-drawer"
    v-model="drawer"
    :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
    :expand-on-hover="true"
    :right="$vuetify.rtl"
    mobile-breakpoint="960"
    app
    width="280"
    v-bind="$attrs"
  >
    <v-divider class="mb-1" />

    <router-link to="/app" class="router-link-style">
      <v-list dense nav style="margin:10px 0px 15px 0px;">
        <v-list-item>
          <v-list-item-avatar class="align-self-center" color="white" contain style="margin-right:8px;">
            <v-img :src="logoImg" />
          </v-list-item-avatar>

          <v-list-item-content>
            <v-list-item-title class="display-1 title-style" v-text="profile.title"/>
          </v-list-item-content>
        </v-list-item>
      </v-list>
    </router-link>

    <v-list expand nav>
      <div />

      <template v-for="(item, i) in computedItems">
        <base-item-group v-if="item.children" :key="`group-${i}`" :item="item">
        </base-item-group>

        <base-item v-else :key="`item-${i}`" :item="item" />
      </template>

      <div />
    </v-list>

    <!--
    <template v-slot:append>
      <base-item
        :item="{
          title: '',
          icon: 'mdi-package-up',
          to: '',
        }"
      />
    </template>
    -->
  </v-navigation-drawer>
</template>

<script>
/* eslint-disable */

// Utilities
import { mapState } from "vuex";
import auth from "../../../../auth";

export default {
  name: "DashboardCoreDrawer",

  props: {
    expandOnHover: {
      type: Boolean,
      default: false,
    },
  },

  data: () => ({
    logoImg: require(`../../../../assets/chanodil_logo.png`),
    items: [],
  }),

  computed: {
    ...mapState(["barColor"]),
    drawer: {
      get() {
        return this.$store.state.drawer;
      },
      set(val) {
        this.$store.commit("SET_DRAWER", val);
      },
    },
    computedItems() {
      if (auth.user.userType === "customer") {
        // create style, all styles
        this.items.push(
          {
            icon: "post_add",
            title: "Create Style",
            to: "/app/inquiry/new",
          },
          {
            icon: "cases",
            title: "Create Collection",
            to: "/app/collection/new",
          },
          {
            icon: "list",
            title: "Collection List",
            to: "/app/collections",
          },
          // {
          //   icon: "checklist",
          //   title: "My Inquiries",
          //   to: "/app/inquiries/own?inquiry_type=own",
          // },
          {
            icon: "mdi-clock",
            title: "Inactive Styles",
            to: "/app/inquiries/completed_or_inactive"
          }
        );
      } else if (auth.user.userType === "supplier") {
        //  all inquries, own inquiries
        this.items.push(
          {
            icon: "list",
            title: "Collection List",
            to: "/app/collections",
          },
          // {
          //   icon: "checklist",
          //   title: "My Inquiries",
          //   to: "/app/inquiries/own?inquiry_type=own",
          // }
        );
      } else if (auth.user.userType === "account_manager") {
        // create style, all, own and new styles
        this.items.push(
          {
            icon: "post_add",
            title: "Create Style",
            to: "/app/inquiry/new",
          },
          {
            icon: "cases",
            title: "Create Collection",
            to: "/app/collection/new",
          },
          {
            icon: "list",
            title: "Collection List",
            to: "/app/collections",
          },
          // {
          //   icon: "list",
          //   title: "All Inquiries",
          //   to: "/app/inquiries/all?inquiry_type=all",
          // },
          // {
          //   icon: "checklist",
          //   title: "My Inquiries",
          //   to: "/app/inquiries/own?inquiry_type=own",
          // },
          // {
          //   icon: "new_releases",
          //   title: "New Inquiries",
          //   to: "/app/inquiries/new?inquiry_type=new",
          // },
          {
            icon: "mdi-clock",
            title: "Inactive Styles",
            to: "/app/inquiries/completed_or_inactive"
          },
          {
            icon: "mdi-calendar-clock",
            title: "Management Dashboard",
            to: "/app/management_dashboard"
          },
          {
            icon: "mdi-account-multiple",
            title: "View Users",
            to: "/app/users"
          }
        );
      } else if (auth.user.userType === "super_admin") {
        this.items.push(
          {
            icon: "list",
            title: "Collection List",
            to: "/app/collections",
          },
          {
            icon: "mdi-clock",
            title: "Inactive Styles",
            to: "/app/inquiries/completed_or_inactive"
          }
        );
      }

      return this.items.map(this.mapItem);
    },
    profile() {
      return {
        avatar: true,
        title: this.$t("avatar"),
      };
    },
  },

  methods: {
    mapItem(item) {
      return {
        ...item,
        children: item.children ? item.children.map(this.mapItem) : undefined,
        title: this.$t(item.title),
      };
    },
  },
};
</script>

<style lang="sass">
@import '~vuetify/src/styles/tools/_rtl.sass'

#core-navigation-drawer
  background: linear-gradient(to right, rgba(52, 32, 87, 0.8) 20%, rgba(66, 45, 105, 0.8) 100%)
  box-shadow: -1px 0px 23px rgba(65, 45, 98, 0.15)
  backdrop-filter: blur(49.5px)
  min-width: 85px
  z-index: 100
  text-align: left
  .v-list-group__header.v-list-item--active:before
    opacity: .24

  .v-list-item
    &__icon--text,
    &__icon:first-child
      justify-content: center
      text-align: center
      width: 20px

      +ltr()
        margin-right: 24px
        margin-left: 12px !important

      +rtl()
        margin-left: 24px
        margin-right: 12px !important

  .v-list--dense
    .v-list-item
      &__icon--text,
      &__icon:first-child
        margin-top: 10px

  .v-list-group--sub-group
    .v-list-item
      +ltr()
        padding-left: 8px

      +rtl()
        padding-right: 8px

    .v-list-group__header
      +ltr()
        padding-right: 0

      +rtl()
        padding-right: 0

      .v-list-item__icon--text
        margin-top: 19px
        order: 0

      .v-list-group__header__prepend-icon
        order: 2

        +ltr()
          margin-right: 8px

        +rtl()
          margin-left: 8px

  .title-style
    width: 90px
    white-space: break-spaces
    font-size: 19px !important
    line-height: 16px

  .router-link-style
    text-decoration-color: #c6a8ec
    display: flex
    justify-content: center
    text-decoration: none

</style>
